.__react_component_tooltip {
    font-family: $font-family-header !important;
    text-transform: none !important;
    i {
        font-style: normal;
        color:$bt-brand-primary !important;
    }
}
.__react_component_tooltip.type-dark {
    background-color: $bt-brand-primary-dark;
}
