@import "../variables";
.dark {
	.chip {
		background-color: $dark-bg-10;
		.chip-icon {
			color: $input-placeholder-color-dark;
		}
	}
}
.chip {
	align-self: flex-start !important;
	background-color: $input-placeholder-color;
	padding: 5px 10px;
	border-radius: 16px;
	margin-right: $input-padding-x;
	font-size: 12px;
	font-weight: bold;
	color: #777;
	.chip-icon {
		font-size: 2em;
		cursor: pointer;
		margin-left: $input-padding-x;
		width: 18px;
		height: 18px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
		color: $input-placeholder-color;
		background: rgba(0,0,0,.2);
	}
	&--active {
		color: white;
		background: $bt-brand-primary;
		.chip-icon {
			color: white;
			background: rgba(0,0,0,.2);
		}
	}
	&.light {
		color: white;
	}
}
