.react-select{
  input[type="text"] {
    border: none !important;
  }
  &__control {
    background: $input-bg-dark;
    height: $input-height !important;
    border-radius: $border-radius-default !important;
  }
  &__value-container {
    padding: 0px 8px;
  }
  &__option {
    color: $body-text !important;
    background: white !important;
    &:hover {
      background: $bt-brand-primary !important;
      color: white !important;
    }
  }
}
.dark {
  .react-select {
    input[type="text"] {
      color: white !important;
      border: none !important;
    }
    &__control {
      background: $input-bg-dark;
      border: none;
    }
    &__single-value {
      color: $input-color-dark;
    }
    &__indicator-separator {
      display: none;
    }
    &__menu {
      background: $input-bg-dark;
      color: $input-color-dark;
    }
    &__option {
      color: white !important;;
      background: $dark-bg-0 !important;
      &:hover {
        background: darken($dark-bg-0,2) !important;
      }
    }
  }
}
.select-sm {
  .react-select {
    &__control {
      border: none;
      height: 24px !important;
      min-height: 24px !important;
    }
    &__value-container {
      margin-top: -7px;
    }
    &__indicator {
      margin-top: -6px;
    }
    &__indicator-separator {
      display: none;
    }
  }
}
.react-select__option {
  padding: 8px 12px;
}
