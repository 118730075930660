@import "../variables";

textarea {
	width: 100%;
	height:40px;
	min-height:40px;
	outline: none;
	border: 1px solid $input-border-color;
	border-radius: 8px;
	padding-top: 8px;
	text-indent: 10px;
	&.borderless {
		border-left: none !important;
		border-top: none !important;
		border-right: none !important;
		border-radius: 0;
	}
	&::placeholder{
		color:$input-placeholder-color-dark !important;
	}
	&:focus {
		border-color: $bt-input-border-active;
	}
}

@import '~react-datepicker/dist/react-datepicker.css';


.input-container, .react-datepicker-wrapper .react-datepicker__input-container {
	font-family: $font-family;
	font-size: $input-font-size;
	line-height: $input-line-height;
	height: $input-height;
	display: inline-block;
	position: relative;
	transition: height 200ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
	background-color: transparent !important;
	&.invalid hr, &.invalid hr.highlight {
		border-color: $alert-danger-border;
	}

	label {
		position: absolute;
		line-height: 22px;
		top: 38px;
		transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
		z-index: 1;
		cursor: text;
		transform: perspective(1px) scale(0.75) translate3d(0px, -28px, 0px);
		transform-origin: left top 0px;
		pointer-events: none;
		color: rgba(0, 0, 0, 0.298039);
		-webkit-user-select: none;
	}
	input.input, input[type="text"] {
		width: 100%;
		text-indent: 15px;
		border: 1px solid $input-border-color;
		outline: none;
		box-shadow: none;
		background-image: none;
		-webkit-appearance: none;
		background-image: none;
		border-radius: $border-radius-default;

		&:disabled {
			opacity: 0.5;
		}
		&:read-only {
			color: #777;
		}
	}

		input, input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill {
		font-weight: $input-font-weight;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
		padding: 0px;
		position: relative;
		width: 100%;
		border: none;
		outline: none;
		box-shadow: none;
		background-image: none;
		-webkit-appearance: none;
		background-image: none;
		color: rgba(0, 0, 0, 0.870588);
		height: 100%;
		background-color: $input-bg;
		&::-webkit-input-placeholder {
			font-family: $font-family;
			font-weight: 100;
		}
	}

	hr {
		border-bottom-width: 1px;
		border-style: none none solid;
		border-color: $input-border-color;
		bottom: 8px;
		box-sizing: content-box;
		margin: 0px;
		position: absolute;
		width: 100%;
		&.highlight {
			border-bottom-width: 2px;
			border-color: $input-border-highlight-color;
			transform: scaleX(0);
			transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
		}
	}

	&.error hr {
		border-color: $alert-danger-border;
	}

	&.error, &.focused {
		hr.highlight {
			transform: scaleX(1);
		}
	}
}



.dark .input-container, .dark .react-datepicker-wrapper .react-datepicker__input-container {
	input, input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill {
		background-color: $input-bg-dark;
		color: $body-text-dark;
	}
}
.dark textarea {
	background-color: $input-bg-dark;
	color: $body-text-dark;
	border-color: $bt-input-border-dark;
	&::placeholder {
		color: $input-placeholder-color-dark !important;
	}
}

.input-container.password {
	input {
		padding-right: 38px;

	}
}

.input-icon-right {
	position: absolute;
	font-size: 24px;
	right:10px;
	top:8px;
}
.react-datepicker-time__input input {
	border: 1px solid $input-border-color;
	padding:2px;
	border-radius: $border-radius-default;
	color: white;
	background-color: $bt-brand-primary;
	&::-webkit-calendar-picker-indicator{
		filter: invert(48%) sepia(13%) saturate(0%) hue-rotate(130deg) brightness(200%) contrast(80%);
	}
}
.react-datepicker__header--time {
	height: 60px;
}
.dark {
	.react-datepicker-time__input input {
		border: 1px solid $input-border-color;
		padding:2px;
		border-radius: $border-radius-default;
		color: white;
		background-color: $bt-brand-primary-dark;
		&::-webkit-calendar-picker-indicator{
			filter: invert(48%) sepia(13%) saturate(0%) hue-rotate(130deg) brightness(200%) contrast(80%);
		}
	}
	.react-datepicker__day--disabled {
		opacity: 0.25;
	}
	.react-datepicker__day-name, .react-datepicker__current-month, .react-datepicker-time__header {
		color: $header-color-dark;

	}
	.react-datepicker__day {
		color: $dark-color;
	}
	.react-datepicker__header, .react-datepicker-time__header {
		background-color: $panel-heading-bg;
		border-color: transparent;
	}
	.react-datepicker-time__heade {

	}
	.react-datepicker__time-container {
		border-left-color: $panel-bg-dark;
	}
	.react-datepicker, .react-datepicker__time {
		border-color: $panel-bg-dark;
		color: $dark-color;
		background-color: $panel-bg-dark;
	}
}
