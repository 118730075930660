.step-list {
    list-style-type: none;
    padding-left:0;
    li{
        height:40px;
        width:40px;
        padding:5px;
        border:1px solid #2e2e2e;
        border-radius: 50%;

        span{
            display: block;
            background:#2e2e2e;
            height:28px;
            width:28px;
            line-height: 28px;
            text-align: center;
            border-radius: 50%;
            margin:0 auto;
            color:#fff;
        }
    }
}

.search-list, .List {
    overflow-x: hidden !important;
}
