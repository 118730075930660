.clearfix:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
}
.bg-secondary {
    background-color: $bt-brand-secondary;
}
.clearfix { display: inline-block; }
/* start commented backslash hack \*/
* html .clearfix { height: 1%; }
.clearfix { display: block; }
/* close commented backslash hack */

.standard-case {
    text-transform: none !important;
}
.float-right{
    float:right;
}
.text-light {
    color: white !important;
}
.form-cta {
    text-align: right;
}

.justify-content-center {
    justify-content: center;
}

.hidden {
    display: none;
}
.relative {
    position: relative !important;
}
.float-left{
    float:left;
}

.margin-top{
    margin-top:20px;
}

.margin-bottom{
    margin-bottom:20px;
}

.no-mb{
    margin-bottom:0;
}

.text-left{
    text-align: left;
}

.link-style{
    text-decoration: underline;
}


.disable-transitions {
    .fade {
        transition: none!important;
    }
}

.e2e {
    background-color: rgba(255,255,255,0.75);
    pointer-events: none !important;
    position:fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10000000000;
}

.show {
    display: block!important;
}

.ml-auto, .mx-auto {
    margin-left: auto!important;
}

.mr-auto, .mx-auto {
    margin-right: auto!important;
}

.signup-form {
    width: 485px;
}

.signup-icon {
    width: 64px;
    path {
        fill: $bt-brand-primary;
    }
}

.align-start {
    align-items: flex-start;
}

.transition-none {
    transition: none !important;
    .modal-content {
        transition: none !important;
    }
}

.text-primary {
    color: $bt-brand-primary !important;
}

.text-primary {
    color: $bt-brand-primary !important;
}

.unread {
    display: inline-block;
    border-radius: 20px;
    background: $bt-brand-primary;
    font-weight: bold;
    padding-left: 5px;
    padding-right: 5px;
    height: 16px;
    min-width: 16px;
    color: #fff;
    text-align: center;
    line-height: 17px;
    font-size: 11px;
    cursor: pointer;
    position: relative;
    bottom: 1px;
}
.aside__environment-list-item.active {
    .unread {
        background-color: rgba(0,0,0,.2);
    }
}

.justify-content-end {
    justify-content: flex-end;
}
