@mixin transition($transition...) {
  -moz-transition:    $transition;
  -o-transition:      $transition;
  -webkit-transition: $transition;
  transition:         $transition;
}
@mixin transition-property($property...) {
  -moz-transition-property:    $property;
  -o-transition-property:      $property;
  -webkit-transition-property: $property;
  transition-property:         $property;
}
@mixin transition-duration($duration...) {
  -moz-transition-property:    $duration;
  -o-transition-property:      $duration;
  -webkit-transition-property: $duration;
  transition-property:         $duration;
}
@mixin transition-timing-function($timing...) {
  -moz-transition-timing-function:    $timing;
  -o-transition-timing-function:      $timing;
  -webkit-transition-timing-function: $timing;
  transition-timing-function:         $timing;
}
@mixin transition-delay($delay...) {
  -moz-transition-delay:    $delay;
  -o-transition-delay:      $delay;
  -webkit-transition-delay: $delay;
  transition-delay:         $delay;
}